<template>
    <div id="container-img-splash" class="d-flex flex-column align-items-center my-md-0 mb-4 mt-md-8" v-if="selected">
        <v-img contain class="image-splash" :src="selected.src" :style="selected.style"></v-img>
        <h1 class="caption-splash text-center h-100" v-html="selected.label"></h1>
    </div>
</template>

<script>

    import planet from "../assets/images-splash/planet.png";
    import cloud from "../assets/images-splash/cloud.png";
    import target from "../assets/images-splash/target.png";
    import megaphone from "../assets/images-splash/megaphone.png";
    import smartphone from "../assets/images-splash/smartphone.png";

    export default {
        name: "ImageSplash",

        data: () => ({
            selected: null,
            images: [
                {
                    name: "planet",
                    src: planet,
                    label: `Coletando <font color="#269D4F">notícias jurídicas</font> todos os dias`,
                    style: {
                        transform: 'translateY(0px)',
                        animation: 'float 6s ease-in-out infinite',
                    }
                },
                {
                    name: "cloud",
                    src: cloud,
                    label: `Receba notícias de <font color="#2F80ED">diversas fontes</font>&nbsp`,
                    style: {
                        transform: 'translateY(0px)',
                        animation: 'float 6s ease-in-out infinite',
                    }
                },
                {
                    name: "target",
                    src: target,
                    label: `<font color="#EB5757">Escolha os temas</font> de seu interesse<br/>&nbsp`,
                    style: {
                        transform: 'rotate(-3deg)',
                        animation: 'rotate 6s ease-in-out infinite',
                    }
                },
                {
                    name: "megaphone",
                    src: megaphone,
                    label: `Receba suas notícias <font color="#5A57EB">por onde quiser </font>`,
                    style: {
                        transform: 'translateY(0px)',
                        animation: 'float 6s ease-in-out infinite',
                    }
                },
                {
                    name: "smartphone",
                    src: smartphone,
                    label: `Seja atualizado com as <font color="#F2994A">últimas notícias</font>`,
                    style: {
                        transform: 'rotate(-3deg)',
                        animation: 'rotate 6s ease-in-out infinite',
                    }
                },
            ],
        }),

        props: {
            pSelectedIndex: {
                type: Number,
                default: 0,
            }
        },

        created() {
            this.selected = this.images[this.pSelectedIndex];

        }
    };
</script>

<style>
    @keyframes float {
        0%   { transform: translateY(  0px);  }
        50%  { transform: translateY(-20px);}
        100% { transform: translateY(  0px);  }
    }

    @keyframes rotate {
        0%   { transform: rotate(-3deg);  }
        50%  { transform: rotate( 3deg);   }
        100% { transform: rotate(-3deg); }
    }

    .image-splash {
        width: max(400px, 10vw);
        height: auto;
    }


    .caption-splash {
        margin: 0;
        font-size: 56px;
        line-height: 60px;
    }

    #container-img-splash {
        width: 25vw;
    }

    @media (max-width: 960px) {
        .image-splash {
            width: 5vw;
            height: auto;
        }

        section {
            margin: 10px 10px 30px 10px;
        }

        .image-splash {
            width: 50vw;
        }

        #container-img-splash {
            width: 50vw;
        }

        .caption-splash {
            font-size: 32px;
            line-height: 36px;
        }
    }
</style>