<template>
    <footer v-if="hasFetchedVersion">
        <div class="footer d-flex flex-row justify-content-between pa-0 pt-2 mb-4">
            <div class="text-left" id="footer-left">
                <strong>Tobias Informador</strong> {{version.length > 0 ? 'v. ' + version : ''}} 2021
            </div>
            <div class="text-right" id="footer-right">
                <strong>CODIN</strong> | Coordenadoria de Informática da Procuradoria-Geral do Estado de Sergipe
            </div>
        </div>
    </footer>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Footer",

        data: () => ({
            version: "",
            hasFetchedVersion: false,
            errVersion: false,
        }),

        methods: {
            getVersion: function () {
                axios.get('/api/home/getVersion')
                    .then(res => {
                        this.version = res.data;
                        this.hasFetchedVersion = true;
                        this.errVersion = false;
                    })
                    .catch(err => {
                        this.version = "";
                        this.hasFetchedVersion = true;
                        this.errVersion = err
                    })
            }
        },

        created() {
            this.getVersion();
        }
    };
</script>

<style>
    .footer {
        border-top: 1px solid #AAA;
        bottom: 0;
        height: 60px;

        margin-top: 30px;

        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
    }

    #footer-left {
        max-width: 30vw;
    }

    #footer-right {
        max-width: 60vw;
    }
</style>