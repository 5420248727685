<template>
    <div class="h-100 d-flex flex-column justify-content-between">
        <section class="container-form d-flex flex-lg-row flex-column-reverse justify-content-between">
            <div class="text-form mt-4 mt-lg-0">
                <h1 class="mb-5 text-center text-lg-left">Bem-vindo ao Tobias Informador</h1>
                <p v-html="text" class="paragraph-form" />

                <v-btn :style="styleButton.login"
                       elevation="0"
                       block
                       class="btn mt-5"
                       to="/login">Entrar</v-btn>

                <v-btn :style="styleButton.register"
                       elevation="0"
                       block
                       class="btn mt-3"
                       to="/cadastro">Cadastrar com a conta do SGP</v-btn>
            </div>
            <hr class="d-md-hidden d-inline" />
            <ImageSplash v-if="$widerThan('md', $vuetify.breakpoint.name)" :pSelectedIndex="Math.floor(5 * Math.random())"></ImageSplash>
        </section>

        <FooterComponent />
    </div>
</template>

<script>

    import ImageSplash from '../components/ImageSplash.vue';
    import FooterComponent from "../components/Footer.vue";

    export default {
        name: 'SplashScreen',

        components: {
            FooterComponent,
            ImageSplash,
        },

        data() {
            return ({
                text:
                    `
                      O Tobias é uma Inteligência Artificial projetada para Coletar, Refinar e <strong>Informar</strong>.<br/><br/>
                      Todos os dias, são coletados informes dos principais portais jurídicos e com o <strong>Tobias Informador</strong>
                      você pode escolher os temas de seu interesse e receber em seu e-mail sempre que houver novas notícias!
                    `,
                styleButton: {
                    login: {
                        backgroundColor: this.$colors.news,
                        color: this.$colors.grays.white,
                    },
                    register: {
                        backgroundColor: this.$colors.primary,
                        color: this.$colors.grays.white,
                    }
                },
            })
        },
    }
</script>

<style>
</style>